import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    top: ({ withLayout }) => (withLayout ? 87 : 24)
  },
  alert: {
    borderRadius: 8,
    boxShadow: '0px 0px 6px #00000033'
  }
}))

export default useStyles
