import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react'
import PropType from 'prop-types'

import Snackbar from '../components/Snackbar'

export const SnackbarContext = createContext()

const initialState = {
  open: false,
  severity: '',
  message: '',
  withLayout: false
}

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        open: true,
        withLayout: action.withLayout,
        severity: action.severity,
        message: action.message
      }
    case 'close':
      return {
        open: false,
        withLayout: state.withLayout,
        severity: state.severity,
        message: state.message
      }
    default:
      throw new Error()
  }
}

export const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleOpenSnackbar = useCallback(
    ({ severity, message, withLayout = false }) =>
      dispatch({ type: 'open', severity, message, withLayout }),
    []
  )

  const handleClose = useCallback(() => dispatch({ type: 'close' }), [])

  return (
    <SnackbarContext.Provider value={{ handleOpenSnackbar }}>
      <Snackbar
        open={state.open}
        message={state.message}
        handleClose={handleClose}
        severity={state.severity}
        withLayout={state.withLayout}
      />
      {children}
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: PropType.node.isRequired
}

export function useSnackbar() {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  return context
}
