import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Slide } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import useStyles from './styles'

const TransitionRight = (props) => <Slide {...props} direction="left" />

const CustomSnackbar = ({
  open,
  handleClose,
  message,
  severity,
  withLayout
}) => {
  const classes = useStyles({ withLayout })
  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionRight}
      autoHideDuration={6000}
    >
      <MuiAlert
        className={classes.alert}
        onClose={handleClose}
        variant="filled"
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
  withLayout: PropTypes.bool.isRequired
}

CustomSnackbar.defaultProps = {
  severity: 'success'
}

export default CustomSnackbar
